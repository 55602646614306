import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

const Text = ({ children, ...props }) => {
  return <S.TextComponent {...props}>{children}</S.TextComponent>;
};

export const ParagraphLarge = (props) => {
  return <S.ParagraphLarge {...props} />;
};

export const ParagraphSmall = (props) => {
  return <S.ParagraphSmall {...props} />;
};

Text.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array,
    PropTypes.node,
  ]),
  type: PropTypes.oneOf([
    'H1',
    'H2',
    'H3',
    'H4',
    'H5',
    'H6',
    'Paragraph Large',
    'Paragraph Medium',
    'Paragraph Small',
    'Caption',
  ]).isRequired,
  color: PropTypes.string,
  bold: PropTypes.bool,
  style: PropTypes.any,
  mb: PropTypes.string,
  mt: PropTypes.string,
  paddingLeft: PropTypes.string,
  fontWeight: PropTypes.string,
  lineHeight: PropTypes.string,
  fontSize: PropTypes.string,
  textAlign: PropTypes.oneOf(['center', 'left', 'right']),
};

export default Text;
