import PropTypes from 'prop-types';

const DISPLAY_TYPES = [
  'flex',
  'flexbox',
  'block',
  'grid',
  'inline',
  'inline-flex',
  'inline-block',
];

const JUSTIFY_CONTENT_TYPES = [
  'center',
  'baseline',
  'end',
  'flex-start',
  'flex-end',
  'space-around',
  'space-between',
];

const ALIGN_ITEMS_TYPES = [
  'center',
  'baseline',
  'flex-start',
  'flex-end',
  'stretch',
  'initial',
];

const FLEX_DIRECTION_TYPES = [
  'column',
  'column-reverse',
  'row',
  'row-reverse',
  'inherit',
  'initial',
  'unset',
];

const CONTBANK_STYLED_PROPTYPES = {
  mb: PropTypes.string,
  mt: PropTypes.string,
  ml: PropTypes.string,
  mr: PropTypes.string,
  maxW: PropTypes.string,
  h: PropTypes.string,
  w: PropTypes.string,
  pt: PropTypes.string,
  pb: PropTypes.string,
  pl: PropTypes.string,
  pr: PropTypes.string,
  gap: PropTypes.string,
  flex: PropTypes.bool,
  display: PropTypes.oneOf(DISPLAY_TYPES),
  flexDirection: PropTypes.oneOf(FLEX_DIRECTION_TYPES),
  justifyContent: PropTypes.oneOf(JUSTIFY_CONTENT_TYPES),
  alignItem: PropTypes.oneOf(ALIGN_ITEMS_TYPES),
};

export default CONTBANK_STYLED_PROPTYPES;
