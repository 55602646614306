import React from 'react';
import { ButtonBox, Label, Loading } from './styles';
import PropTypes from 'prop-types';
import CONTBANK_STYLED_PROPTYPES from 'styles/styled-proptypes';
import CONTBANK_STYLED_PROPS from 'styles/styled-props';

const BtnPropTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'yellow',
    'black',
    'white',
    'danger',
    'transparent',
    'neutral',
    'grey',
  ]),
  variant: PropTypes.oneOf(['outlined', 'contained', 'modal']),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  noTextOverflow: PropTypes.bool,
  noMarginBottom: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.node,
    PropTypes.string,
  ]),
  ...CONTBANK_STYLED_PROPTYPES,
};

/**
 * @type React.FC<BtnPropTypes>
 */
const RoundedButton = React.forwardRef(
  (
    {
      children,
      className,
      noTextOverflow,
      color,
      variant,
      loading,
      ...props
    } = CONTBANK_STYLED_PROPS,
    ref
  ) => {
    return (
      <ButtonBox
        className={[className, 'btn']}
        color={color}
        variant={variant}
        ref={ref}
        {...props}
      >
        <Label className="label" noTextOverflow={noTextOverflow}>
          {children}
          {loading && <Loading />}
        </Label>
      </ButtonBox>
    );
  }
);

RoundedButton.propTypes = BtnPropTypes;

RoundedButton.defaultProps = {
  color: 'yellow',
  variant: 'contained',
  noMarginBottom: false,
  loading: false,
};

export default RoundedButton;
