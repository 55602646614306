import styled, { css } from 'styled-components';
import media from 'styles/media';
import Configs from 'styles/variables';

const cssH1 = css`
  ${media.mobile`
    font-family: ${Configs.typography.mobile.h1.fontFamily};
    font-size: ${Configs.typography.mobile.h1.size};
    line-height: ${Configs.typography.mobile.h1.lineHeight};
  `}
  font-family: ${Configs.typography.desktop.h1.fontFamily};
  font-size: ${Configs.typography.desktop.h1.size};
  line-height: ${Configs.typography.desktop.h1.lineHeight};
  font-weight: ${({ bold }) =>
    bold
      ? Configs.typography.mobile.h1.fontWeightBold
      : Configs.typography.mobile.h1.fontWeight}}
`;

const cssH2 = css`
  ${media.mobile`
    font-family: ${Configs.typography.mobile.h2.fontFamily};
    font-size: ${Configs.typography.mobile.h2.size};
    line-height: ${Configs.typography.mobile.h2.lineHeight};
    
  `}
  font-family: ${Configs.typography.desktop.h2.fontFamily};
  font-size: ${Configs.typography.desktop.h2.size};
  line-height: ${Configs.typography.desktop.h2.lineHeight};
  font-weight: ${({ bold }) =>
    bold
      ? Configs.typography.mobile.h2.fontWeightBold
      : Configs.typography.mobile.h2.fontWeight}}
`;

const cssH3 = css`
  ${media.mobile`
    font-family: ${Configs.typography.mobile.h3.fontFamily};
    font-size: ${Configs.typography.mobile.h3.size};
    line-height: ${Configs.typography.mobile.h3.lineHeight};
  `}
  font-family: ${Configs.typography.desktop.h3.fontFamily};
  font-size: ${Configs.typography.desktop.h3.size};
  line-height: ${Configs.typography.desktop.h3.lineHeight};
  font-weight: ${({ bold }) =>
    bold
      ? Configs.typography.mobile.h3.fontWeightBold
      : Configs.typography.mobile.h3.fontWeight}}
`;

const cssH4 = css`
  ${media.mobile`
    font-family: ${Configs.typography.mobile.h4.fontFamily};
    font-size: ${Configs.typography.mobile.h4.size};
    line-height: ${Configs.typography.mobile.h4.lineHeight};
  `}
  font-family: ${Configs.typography.desktop.h4.fontFamily};
  font-size: ${Configs.typography.desktop.h4.size};
  line-height: ${Configs.typography.desktop.h4.lineHeight};
  font-weight: ${({ bold }) =>
    bold
      ? Configs.typography.mobile.h4.fontWeightBold
      : Configs.typography.mobile.h4.fontWeight}}
`;

const cssH5 = css`
  ${media.mobile`
    font-family: ${Configs.typography.mobile.h5.fontFamily};
    font-size: ${Configs.typography.mobile.h5.size};
    line-height: ${Configs.typography.mobile.h5.lineHeight};
  `}
  font-family: ${Configs.typography.desktop.h5.fontFamily};
  font-size: ${Configs.typography.desktop.h5.size};
  line-height: ${Configs.typography.desktop.h5.lineHeight};
  font-weight: ${({ bold }) =>
    bold
      ? Configs.typography.mobile.h5.fontWeightBold
      : Configs.typography.mobile.h5.fontWeight}}
`;

const cssH6 = css`
  ${media.mobile`
    font-family: ${Configs.typography.mobile.h6.fontFamily};
    font-size: ${Configs.typography.mobile.h6.size};
    line-height: ${Configs.typography.mobile.h6.lineHeight};
  `}
  font-family: ${Configs.typography.desktop.h6.fontFamily};
  font-size: ${Configs.typography.desktop.h6.size};
  line-height: ${Configs.typography.desktop.h6.lineHeight};
  font-weight: ${({ bold }) =>
    bold
      ? Configs.typography.mobile.h6.fontWeightBold
      : Configs.typography.mobile.h6.fontWeight}}
`;

const cssParagraphL = css`
  ${media.mobile`
    font-family: ${Configs.typography.mobile.paragraphLarge.fontFamily};
    font-size: ${Configs.typography.mobile.paragraphLarge.size};
    line-height: ${Configs.typography.mobile.paragraphLarge.lineHeight};
  `}
  font-family: ${Configs.typography.desktop.paragraphLarge.fontFamily};
  font-size: ${Configs.typography.desktop.paragraphLarge.size};
  line-height: ${Configs.typography.desktop.paragraphLarge.lineHeight};
  font-weight: ${({ bold }) =>
    bold
      ? Configs.typography.mobile.paragraphLarge.fontWeightBold
      : Configs.typography.mobile.paragraphLarge.fontWeight}}
`;

const cssParagraphM = css`
  ${media.mobile`
    font-family: ${Configs.typography.mobile.paragraphMedium.fontFamily};
    font-size: ${Configs.typography.mobile.paragraphMedium.size};
    line-height: ${Configs.typography.mobile.paragraphMedium.lineHeight};
  `}
  font-family: ${Configs.typography.desktop.paragraphMedium.fontFamily};
  font-size: ${Configs.typography.desktop.paragraphMedium.size};
  line-height: ${Configs.typography.desktop.paragraphMedium.lineHeight};
  font-weight: ${({ bold }) =>
    bold
      ? Configs.typography.mobile.paragraphMedium.fontWeightBold
      : Configs.typography.mobile.paragraphMedium.fontWeight}}
`;

const cssParagraphS = css`
  ${media.mobile`
    font-family: ${Configs.typography.mobile.paragraphSmall.fontFamily};
    font-size: ${Configs.typography.mobile.paragraphSmall.size};
    line-height: ${Configs.typography.mobile.paragraphSmall.lineHeight};
  `}
  font-family: ${Configs.typography.desktop.paragraphSmall.fontFamily};
  font-size: ${Configs.typography.desktop.paragraphSmall.size};
  line-height: ${Configs.typography.desktop.paragraphSmall.lineHeight};
  font-weight: ${({ bold }) =>
    bold
      ? Configs.typography.mobile.paragraphSmall.fontWeightBold
      : Configs.typography.mobile.paragraphSmall.fontWeight}}
`;

const cssCaption = css`
  ${media.mobile`
    font-family: ${Configs.typography.mobile.caption.fontFamily};
    font-size: ${Configs.typography.mobile.caption.size};
    line-height: ${Configs.typography.mobile.caption.lineHeight};
  `}
  font-family: ${Configs.typography.desktop.caption.fontFamily};
  font-size: ${Configs.typography.desktop.caption.size};
  line-height: ${Configs.typography.desktop.caption.lineHeight};
  font-weight: ${({ bold }) =>
    bold
      ? Configs.typography.mobile.caption.fontWeightBold
      : Configs.typography.mobile.caption.fontWeight}}
`;

const elementTypes = {
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  H4: 'h4',
  H5: 'h5',
  'Paragraph Large': 'div',
  'Paragraph Medium': 'div',
  'Paragraph Small': 'div',
  Caption: 'div',
};

export const TextComponent = styled.div.attrs(
  (props) => props.type && { as: elementTypes[props.type] }
)`
  color: ${({ color }) => (color ? color : Configs.newColors.n900)};
  ${(props) =>
    props.letterSpacing && `letter-spacing: ${props.letterSpacing};`};
  ${(props) => props.type && props.type === 'H1' && cssH1};
  ${(props) => props.type && props.type === 'H2' && cssH2};
  ${(props) => props.type && props.type === 'H3' && cssH3};
  ${(props) => props.type && props.type === 'H4' && cssH4};
  ${(props) => props.type && props.type === 'H5' && cssH5};
  ${(props) => props.type && props.type === 'H6' && cssH6};
  ${(props) => props.type && props.type === 'Paragraph Large' && cssParagraphL};
  ${(props) =>
    props.type && props.type === 'Paragraph Medium' && cssParagraphM};
  ${(props) => props.type && props.type === 'Paragraph Small' && cssParagraphS};
  ${(props) => props.type && props.type === 'Caption' && cssCaption};

  ${({ lineHeight }) =>
    lineHeight &&
    css`
      line-height: ${lineHeight};
    `};
  ${({ fontWeight }) =>
    fontWeight &&
    css`
      font-weight: ${fontWeight};
    `};
  ${({ mb }) =>
    mb &&
    css`
      margin-bottom: ${mb};
    `};
  ${({ mt }) =>
    mt &&
    css`
      margin-top: ${mt};
    `};

  ${({ paddingLeft }) =>
    paddingLeft &&
    css`
      padding-left: ${paddingLeft};
    `};

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `};

  ${({ textAlign }) =>
    textAlign &&
    css`
      text-align: ${textAlign};
    `};

  ${({ fontSize }) =>
    fontSize &&
    css`
      font-size: ${fontSize};
    `};
`;

export const ParagraphLarge = styled.p`
  ${cssParagraphL}
`;

export const ParagraphSmall = styled.p`
  ${cssParagraphS}
`;
