import React from 'react';
import * as S from './styles';
import NoticeText from './NoticeText';
import { Trans, useTranslation } from 'react-i18next';
import { PATHS } from 'Routes/constants';
import { useHistory } from 'react-router-dom';
import DivComponent from 'components/_v2/DivComponent';

const Notice = () => {
  const { t } = useTranslation(['onboarding']);
  const history = useHistory();

  const handleOnConfirm = () => {
    history.push(PATHS.BANK.DASHBOARD);
  };

  return (
    <S.Container w="100%" pr="0px" pl="0px">
      <S.TitleContainer>
        <S.Title pr="24px" pl="16px">
          <Trans
            ns="common"
            i18nKey="titles.account-closure"
            defaults="Encerramento de Conta e <br/> Procedimento para Transferência de Saldo"
          />
        </S.Title>
      </S.TitleContainer>

      <S.Body pr="16px" pl="16px">
        <NoticeText />
      </S.Body>

      <S.FooterContainer>
        <DivComponent mt="16px" pr="16px" pl="16px" pb="16px" w="100%">
          <S.FooterButton onClick={handleOnConfirm}>
            {t('buttons.accept', 'Aceitar')}
          </S.FooterButton>
        </DivComponent>
      </S.FooterContainer>
    </S.Container>
  );
};

export default Notice;
