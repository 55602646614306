import { css } from 'styled-components';
import media from './media';
import CONTBANK_STYLED_ATTRIBUTES from './styled-attributes';

const mobileRegex = new RegExp(
  /^(m:\d*(px|rem|em|\s|vh|vw|%|auto))|(m:\d*(px|rem|em|\s|vh|vw|%|auto))/
);

const getMobileCss = (value, cssAttr) => {
  value = `${value}`;
  if (value.includes('m:')) {
    let mobileValue = value.match(mobileRegex);

    if (!mobileValue) return null;

    mobileValue = mobileValue[0].replace('m:', '');

    return `
        ${cssAttr}: ${mobileValue};
      `;
  }
  return null;
};

const getDesktopCss = (value, cssAttr) => {
  value = `${value}`;

  let desktopValue =
    value && value.includes('m:') ? value.replace(mobileRegex, '') : value;

  return `
    ${cssAttr}: ${desktopValue};
  `;
};

const filterValues = (arr = [], props, callback) =>
  arr
    .map((key) => {
      let val = props[key];

      if (typeof val === 'boolean') {
        val = key;
      }

      return callback(val, CONTBANK_STYLED_ATTRIBUTES[key]);
    })
    .filter((v) => v !== undefined && v !== null && !!v)
    .join(' ');

export const getPropsValuesCss = (props) => {
  const keys = Object.keys(props);

  const mappedKeys = keys.filter((k) =>
    Object.keys(CONTBANK_STYLED_ATTRIBUTES).includes(k)
  );

  if (mappedKeys.length > 0) {
    const valuesDesktop = filterValues(mappedKeys, props, getDesktopCss);
    const valuesMobile = filterValues(mappedKeys, props, getMobileCss);

    return css`
      ${valuesDesktop ? valuesDesktop : ''};

      ${media.mobile`
        ${valuesMobile ? valuesMobile : ''};
      `}
    `;
  }
};

/*
 * Usage example
 *
 * Add prop in contbank styled attributes
 * const CONTBANK_STYLED_ATTRIBUTES = {
 *  minHeight: 'min-height',
 * };
 *
 * update contbank styled proptypes
 *
 * export const Wrapper = styled.div`
 * ${(props) => getPropsValuesCss(props)};
 * `;
 *
 * const ModalWrapper = ({ children, minHeight, width }) => {
 *  return <S.Wrapper minHeight={minHeight} width={width}>{children}</S.Wrapper>;
 * };
 *
 * <Wrapper minHeight="150px m:300px" width="100% m:150px" />
 *
 */
