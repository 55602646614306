const CONTBANK_STYLED_ATTRIBUTES = {
  mb: 'margin-bottom',
  mt: 'margin-top',
  ml: 'margin-left',
  mr: 'margin-right',
  maxW: 'max-width',
  minW: 'min-width',
  maxH: 'max-height',
  minH: 'min-height',
  h: 'height',
  w: 'width',
  pt: 'padding-top',
  pb: 'padding-bottom',
  pl: 'padding-left',
  pr: 'padding-right',
  display: 'display',
  gap: 'gap',
  flexDirection: 'flex-direction',
  justifyContent: 'justify-content',
  alignItems: 'align-items',
  flex: 'display',
  textAlign: 'text-align',
  overflow: 'overflow',
};

export default CONTBANK_STYLED_ATTRIBUTES;
