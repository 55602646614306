import React, { forwardRef } from 'react';
import CONTBANK_STYLED_PROPTYPES from 'styles/styled-proptypes';
import CONTBANK_STYLED_PROPS from 'styles/styled-props';
import * as S from './styles';

const DivComponent = forwardRef(
  ({ children, ...props } = CONTBANK_STYLED_PROPS, ref) => {
    return (
      <S.Container ref={ref} {...props}>
        {children}
      </S.Container>
    );
  }
);

DivComponent.propTypes = {
  ...CONTBANK_STYLED_PROPTYPES,
};

export default DivComponent;
