import styled, { css } from 'styled-components';
import { getPropsValuesCss } from 'styles/styled-functions';
import Configs from 'styles/variables';
import Reticence from 'components/_v2/Reticence';

const modalCss = css`
  position: relative;
  padding: 0;
  width: 100%;
  height: 48px;
  margin: 0;
`;

const outlinedCss = css`
  background: transparent;
  color: inherit;
`;

const transparentCss = css`
  border: 1px solid ${Configs.newColors.n0};
  background: transparent;
  color: ${Configs.newColors.n0};
`;

const outlinedHoverCss = css`
  border: 1px solid black;
  background: ${Configs.newColors.n100};
  color: ${Configs.black};
`;

const yellowHoverCss = css`
  border: 1px solid ${Configs.newColors.p800};
  background: ${Configs.newColors.p800};
  color: ${Configs.black};
`;

const dangerHoverCss = css`
  border: 1px solid ${Configs.newColors.a400};
  background: ${Configs.newColors.a100};
  color: ${Configs.newColors.a400};
`;

const dangerCss = css`
  border: 1px solid ${Configs.newColors.a400};
  background: ${Configs.newColors.a400};
  color: ${Configs.newColors.n0};
`;

const neutralCss = css`
  border: 1px solid ${Configs.newColors.n50};
  background: ${Configs.newColors.n50};
  color: ${Configs.newColors.n300};
`;

const yellowCss = css`
  border: 1px solid ${Configs.yellow};
  background: ${Configs.yellow};
  color: ${Configs.black};
`;

const whiteCss = css`
  border: 1px solid ${Configs.black};
  background: ${Configs.newColors.n0};
  color: ${Configs.black};
`;

const greyCss = css`
  border: 1px solid ${Configs.newColors.n200};
  background: ${Configs.newColors.n0};
  color: ${Configs.newColors.n200};
`;

const blackCss = css`
  border: solid 1px ${Configs.newColors.n900};
  background: ${Configs.newColors.n900};
  color: ${Configs.white};
`;

const blackCssBorder = css`
  border: solid 1px ${Configs.white};
  background: ${Configs.black};
  color: ${Configs.white};
`;

const defaultCss = css`
  border: 1px solid ${Configs.buttons.background};
  background: ${Configs.buttons.background};
  color: ${Configs.buttons.color};
`;

const defaultHoverCss = css`
  border: 1px solid ${Configs.buttons.hover.background};
  background: ${Configs.buttons.hover.background};
  color: ${Configs.buttons.hover.color};
`;

const getVariantCss = (variant) => {
  switch (variant) {
    case 'outlined':
      return outlinedCss;
    case 'modal':
      return modalCss;
    default:
      return '';
  }
};

const getButtonCss = (color) => {
  switch (color) {
    case 'black':
      return blackCss;
    case 'grey':
      return greyCss;
    case 'white':
      return whiteCss;
    case 'yellow':
      return yellowCss;
    case 'danger':
      return dangerCss;
    case 'transparent':
      return transparentCss;
    case 'neutral':
      return neutralCss;
    default:
      return defaultCss;
  }
};

const getButtonHoverCss = ({
  black,
  white,
  yellow,
  danger,
  tertiary,
  outlined,
  newType,
}) => {
  if (black) return yellow ? yellowCss : whiteCss;
  if (yellow) {
    if (white) return whiteCss;
    return newType ? yellowHoverCss : blackCssBorder;
  }
  if (white) return yellow ? yellowCss : blackCss;
  if (outlined) return outlinedHoverCss;
  if (danger) return dangerHoverCss;
  return defaultHoverCss;
};

export const ButtonBox = styled.button.attrs((props) => ({
  paddingX: props.big ? 35 : Configs.buttons.paddingX,
  paddingY: props.big ? 35 : props.medium ? 24 : 0,
  size: 24,
}))`
  border: none;
  display: flex;
  height: ${Configs.buttons.height}px;
  justify-content: center;
  align-items: center;
  margin-bottom: ${(props) => (props.noMarginBottom ? '0px' : '26px;')};
  border-radius: ${(props) => props.paddingX}px;
  font-size: ${(props) => props.size}px;
  padding: ${(props) => props.paddingY}px ${(props) => props.paddingX}px;
  transition: all 0.25s ease;
  box-shadow: ${(props) => (props.big ? '0 0 10px 0 rgba(0, 0, 0, 0.09)' : '')};
  cursor: pointer;
  position: relative;
  ${({ minHeight }) => minHeight && `min-height: ${minHeight}`};
  ${({ color }) => getButtonCss(color)}
  ${({ variant }) => getVariantCss(variant)}
  ${getPropsValuesCss};
  /* :hover {
    ${(props) => getButtonHoverCss(props)}
  } */

  &.active {
    background: ${Configs.buttons.active.background};
    color: ${Configs.buttons.active.color};
  }
  &.inactive,
  &[disabled] {
    background: ${Configs.newColors.n100};
    color: ${Configs.newColors.n400};
    cursor: not-allowed;
    border-color: ${Configs.newColors.n100};
    & svg {
      fill: ${Configs.newColors.n400};
    }
    & > .label {
      cursor: not-allowed;
    }
  }
  &.hasIcon {
    svg {
      margin-left: 8px;
      margin-right: -8px;
    }
  }
`;

export const Loading = styled(Reticence)``;

export const Label = styled.div`
  padding: 1rem 0;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: block;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  ${({ noTextOverflow }) =>
    noTextOverflow &&
    css`
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `};
`;
