import CONTBANK_STYLED_ATTRIBUTES from './styled-attributes';
/*
 * This props are generated from contbank styled attributes
 * when create new attribute this props get automatic and
 * add with null value. This object is used to get default
 * props autocomple in components
 *
 * Usage example:
 * const Component = ({children, anotherProp, anotherAnotherProp, ...props } = CONTBANK_STYLED_PROPS) => {}
 *
 */
const CONTBANK_STYLED_PROPS = {
  ...Object.keys(CONTBANK_STYLED_ATTRIBUTES).reduce(
    (prevKey, key, index, acc) => {
      acc[key] = null;
      return acc;
    }
  ),
};

export default CONTBANK_STYLED_PROPS;
