import { getPropsValuesCss } from 'styles/styled-functions';
import styled from 'styled-components';
import ComponentText from 'components/_v2/Text';
import DivComponent from 'components/_v2/DivComponent';
import Configs from 'styles/variables';
import RoundedButton from 'components/_v2/RoundedButton';
import media from 'styles/media';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  height: 100vh;

  ${media.mobile`
    padding: 50px 16px 0;
  `};
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 20%;
`;
export const Title = styled(ComponentText).attrs({
  type: 'H2',
})`
  margin: 0;
  margin-bottom: 44px;

  ${getPropsValuesCss};
`;

export const Body = styled(DivComponent)`
  flex-grow: 1;
  overflow-y: auto;
  padding: 32px 0 32px 16px;
`;

export const FooterContainer = styled(DivComponent)`
  border-top: 1px solid ${Configs.newColors.n100};
  width: 100%;
  min-height: 80px;
`;

export const FooterButton = styled(RoundedButton)`
  padding: 0;
  width: 100%;
  height: 48px;
  margin: 0;
  ${getPropsValuesCss};
`;

export const NoticeText = styled.div`
  color: ${Configs.black};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;

  ul {
    padding-left: 32px;
  }

  p {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
  }

  b {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
  }

  h2 {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
  }

  ${getPropsValuesCss};
`;
