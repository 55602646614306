/* eslint-disable no-trailing-spaces */
import React, { forwardRef } from 'react';
import * as S from './styles';

const NoticeText = forwardRef((props, ref) => {
  return (
    <S.NoticeText ref={ref}>
      <h2>Prezado(a) {props.name},</h2>
      <br />
      Por meio da presente notificação, vem o <strong>CONTBANK S.A</strong>, em
      conformidade com o artigo 12 inciso I, da Resolução BCB nº 96, comunicar
      que sua conta pessoa física será encerrada em{' '}
      <strong>(24/03/2025)</strong>.
      <br />
      Caso haja saldo remanescente e você não conseguir realizar a transferência
      diretamente pelo aplicativo, você deverá solicitar a{' '}
      <span>transferência dos valores depositados até o dia 20/03/2025</span>.
      Após essa data, eventuais valores não resgatados só poderão ser requeridos
      diretamente ao Banco Central.
      <br />
      <br />
      <h2>Procedimentos para solicitar a transferência:</h2>
      <br />
      1.Envie um email para <strong>ajuda@contbank</strong> e/ou mensagem no
      Whatsapp <strong>(11)93726-4265</strong> com o assunto{' '}
      <strong>"Solicitação de Transferência de Saldo – [Seu Nome]".</strong> No
      corpo do e-mail informe o número do seu CPF.
      <br />
      <br />
      2. No e-mail/Whatsapp, anexe os seguintes documentos para confirmação da
      titularidade:
      <ul>
        <li>Documento de identificação oficial com foto (RG ou CNH);</li>
        <li>Selfie segurando o documento de identificação;</li>
        <li>Dados bancários de conta de mesma titularidade.</li>
      </ul>
      <br />
      Para dúvidas ou suporte, entre em contato pelo e-mail{' '}
      <strong>ajuda@contbank</strong> ou Whatsapp{' '}
      <strong>(11)93726-4265</strong>.
      <br />
      <br />
      Atenciosamente,
      <br />
      <strong>Contbank</strong>
    </S.NoticeText>
  );
});

export default NoticeText;
